/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDeviantart, faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'

import NavBar from "../components/navbar"
import "./layoutBase.css"

const LayoutBase = ({ children }) => {
  /*
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div
        style={{
          margin: `0 auto`,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
      </div>
  */
  return (
    <>
      <NavBar/>
      {children}
      <footer className="footer">
        <ul className="icons">
          <li>
            <a
              className="icon"
              href="https://www.twitter.com/knockoutcats"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faTwitter} />
              <span className="label">Twitter</span>
            </a>
          </li>
          <li>
            <a
              className="icon"
              href="https://www.facebook.com/knockoutcats"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faFacebookF} />
              <span className="label">Facebook</span>
            </a>
          </li>
          <li>
            <a
              className="icon"
              href="https://www.instagram.com/knockoutcats"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faInstagram} />
              <span className="label">Instagram</span>
            </a>
          </li>
          <li>
            <a
              className="icon"
              href="https://www.deviantart.com/knockoutcats"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faDeviantart} />
              <span className="label">DeviantArt</span>
            </a>
          </li>
        </ul>

        <div className="copyright">
          © {new Date().getFullYear()}, {" "}
          <a href="https://www.knockoutcats.com">Knockout Cats</a>
        </div>
      </footer>
    </>
  )
}

LayoutBase.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutBase
