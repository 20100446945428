import React from 'react';
import { Link } from "gatsby";
import { CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';

import './navbar.css';

function NavBar({showMobileMenu, toggleMobileMenu}) {
  const navItems = [
    {to: "/", content: "Home"},
    {to: "/videos", content: "Videos"},
    {to: "/fighters", content: "Fighters"},
    {to: "/blog", content: "Blog"},
    {to: "/about", content: "About"},
  ];
  /*
    {
      to: "/blog",
      content: "Blog",
      children: [
        {to: "/", content: "Lorem"},
        {
          to: "/",
          content: "And a submenu",
          children: [
            {to: "/", content: "Lorem"},
          ]
        },
        {to: "/", content: "Veroeros"},
      ]
    },
  */

  const getNavItemKey = (prefix, item) => {
    return prefix + "-" + item["content"].replace(" ", "-");
  }

  const renderLink = (link) => {
    return (
      <Link to={link["to"]}>
        {link["content"]}
      </Link>
    );
  }

  const renderMobileLink = (indent, link) => {
    return (
      <Link key={getNavItemKey("mnav", link)} className={"link depth-" + indent} to={link["to"]}>
        <span className={"indent-" + indent}></span>
        {link["content"]}
      </Link>
    );
  }

  const renderNavItems = (items) => {
    var elements = []
    for (const item of items) {
      if ("children" in item) {
        elements.push(
          <li key={getNavItemKey("nav", item)}>
            {renderLink(item)}
            {renderNavItems(item["children"])}
          </li>
        );
      } else {
        elements.push(<li key={getNavItemKey("nav", item)}>{renderLink(item)}</li>);
      }
    }

    return <ul>{elements}</ul>
  }

  const renderMobileNavItems = (indent, items) => {
    var elements = []
    for (const item of items) {
      elements.push(renderMobileLink(indent, item))
      if ("children" in item) {
        elements.push(renderMobileNavItems(indent+1, item["children"]));
      }
    }

    return elements
  }

  const renderNav = () => {
    return (
      <nav id="nav">
        {renderNavItems(navItems)}
      </nav>
    );
  }

  const renderMobileNav = () => {
    return (
      <CSSTransition in={showMobileMenu} timeout={5000} classNames="navPanel">
        <div id="navPanel" className="navPanel">
          <nav>
            {renderMobileNavItems(0, navItems)}
          </nav>
        </div>
      </CSSTransition>
    );
  }

  const onNavButtonClick = (e) => {
    e.preventDefault();

    toggleMobileMenu();
  }

  return (
    <div>
      {renderNav()}
      <CSSTransition in={showMobileMenu} timeout={5000} classNames="navButton-navPanel">
      <div id="navButton">
        <a href="#navPanel" onClick={onNavButtonClick}>
          <div className="menuDrawer"><i className="material-icons">menu</i></div>
        </a>
      </div>
      </CSSTransition>
      {renderMobileNav()}
    </div>
  );
}

const mapStateToProps = ({showMobileMenu}) => {
  return { showMobileMenu };
}

const toggleMobileMenu = () => ({
  type: 'TOGGLE_BOOLEAN_KEY',
  payload: { key: "showMobileMenu" }
});

const mapDispatchToProps = dispatch => {
  return {
    "toggleMobileMenu": async () => {
      try {
        dispatch(toggleMobileMenu());
      } catch(err) {
        console.log(err);
      }
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
